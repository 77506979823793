import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Link } from 'gatsby-plugin-react-intl'

import Patientform from '../components/Appointment/patientform'

//import images
import { StaticImage } from 'gatsby-plugin-image'

//import Patientform
// import Patientform from "../components/appointment/patientform"

//styles
import { Container, Col, Row, Button } from 'react-bootstrap'

//import icons
import { FaFacebookMessenger } from 'react-icons/fa'

import * as styles from '../styles/pages/request-appointment.module.scss'

const RequestAppointment = () => (
  <Layout>
    <Seo
      title='Book An Appointment at Kids and Teens'
      description='Learn more about our providers at Kids &amp; Teens Medical Group.  Our team of experts provides a variety of pediatric services to the communities of Los Angeles and surrounding neighborhoods. To book an appointment, call our offices at 818-244-9595'
    />
    <Container>
      <h2
        className={`pt-5 justify-content-center text-center ${styles.urgentcareneeds}`}
      >
        Book Your Appointment At Any Of Our Offices
      </h2>
      <Row className='justify-content-center'>
        <Col
          lg={4}
          className='mt-5 pt-5 d-flex justify-content-center text-center'
        >
          <div className={` ${styles.box6}`}>
            <div className={` ${styles.boxnumber}`}>1</div>
            <h2 className='mt-5 pt-5'>Book Your Appointment</h2>

            <div className='my-4 pt-5'>
              <Button className={` ${(styles.button, styles.single_button)}`}>
                <Link to='/locations'>
                  <strong>Book Appointment</strong>
                </Link>
              </Button>
            </div>
            <h6 className='mt-5'>
              Response time : <span>Fastest</span>
            </h6>
            <p>(Book your appointment online in a few minutes)</p>
          </div>
        </Col>

        <Col
          lg={4}
          className='mt-5 pt-5 d-flex justify-content-center text-center'
        >
          <div className={` ${styles.box1}`}>
            <div className={` ${styles.boxnumber}`}>2</div>
            <h2 className='mt-5 pt-5'>Request via Text</h2>
            <h5 className='mb-5 pb-3'>for In-Office Appointment</h5>
            <div>
              <Button className={` ${styles.button}`}>
                <a href='sms:8186582701'>
                  English <span>818-658-2701</span>
                </a>
              </Button>
            </div>
            <div className='my-2'>
              <Button className={` ${styles.button}`}>
                <a href='sms:8184235637'>
                  Spanish <span>818-423-5637</span>
                </a>
              </Button>
            </div>
            <div>
              <Button className={` ${styles.messenger_button}`}>
                <a href='https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F141266270391%2F%3Fmessaging_source%3Dsource%253Apages%253Amessage_shortlink'>
                  <FaFacebookMessenger className='me-2 mb-1' />
                  Send to Messenger
                </a>
              </Button>
            </div>
            <h6 className='pt-3 mt-5'>
              Response time : <span>Fast</span>
            </h6>
            <p>(Usually within 10 min during office hours)</p>
          </div>
        </Col>

        <Col
          lg={4}
          className='mt-5 pt-5 d-flex justify-content-center text-center'
        >
          <div className={` ${styles.box2}`}>
            <div className={` ${styles.boxnumber}`}>3</div>
            <h2 className='mt-5 pt-5'>Request Telemedicine</h2>
            <h5 className='mb-5 pb-3'>May Include Video</h5>
            <div>
              <Button className={` ${styles.button}`}>
                <a href='sms:8186582701'>
                  English <span>818-658-2701</span>
                </a>
              </Button>
            </div>
            <div className='my-2'>
              <Button className={` ${styles.button}`}>
                <a href='sms:8184235637'>
                  Spanish <span>818-423-5637</span>
                </a>
              </Button>
            </div>
            <div>
              <Button className={` ${styles.messenger_button}`}>
                <a href='https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F141266270391%2F%3Fmessaging_source%3Dsource%253Apages%253Amessage_shortlink'>
                  <FaFacebookMessenger className='me-2 mb-1' />
                  Send to Messenger
                </a>
              </Button>
            </div>
            <h6 className='pt-3 mt-5'>
              <span>From Home</span>
            </h6>
            <p>(Usually within 30 min during office hours)</p>
          </div>
        </Col>

        <Col
          lg={4}
          className='mt-5 pt-5 d-flex justify-content-center text-center'
        >
          <div className={` ${styles.box4}`}>
            <div className={` ${styles.boxnumber}`}>4</div>
            <h2 className='mt-5 pt-5'>Request via Call</h2>

            <div className='my-5 pt-5'>
              <Button className={` ${styles.button} mb-3`}>
                <a href='tel:8183615437'>
                  <strong>(818) 361-5437</strong>
                </a>
              </Button>
              <Button className={` ${styles.button} mb-3`}>
                <a href='tel:6267958811'>
                  <strong>(626) 795-8811</strong>
                </a>
              </Button>
              <Button className={` ${styles.button} mb-3`}>
                <a href='tel:8188830460'>
                  <strong>(818) 883-0460</strong>
                </a>
              </Button>

              <h6 className='pt-3 mt-3'>
                Response time : <span>Fast</span>
              </h6>
              <p>Call us anytime for an appointment</p>
            </div>
          </div>
        </Col>

        <Col
          lg={4}
          className='mt-5 pt-5 d-flex justify-content-center text-center'
        >
          <div className={` ${styles.box3}`}>
            <div className={` ${styles.boxnumber}`}>5</div>
            <h2 className='my-5 py-5'>Request via Online Form</h2>
            <div className='my-2'>
              <Button className={` ${(styles.button, styles.single_button)}`}>
                <a href='#form'>
                  <strong>Online Request</strong>
                </a>
              </Button>
            </div>

            <h6 className='pt-3 mt-5'>
              Response time : <span style={{ color: '#F6C711' }}>Slow</span>
            </h6>
            <p>(Usually within 30 min during office hours)</p>
          </div>
        </Col>

        <Col lg={8} className={` ${styles.box5}`}>
          <h1 className='py-3'>All PPO &amp; Most HMO Insurance Accepted</h1>
          <p>
            We accept most insurance including HMOs, PPOs, and Medi-Cal. If you
            are a new patient and would like to verify if we accept your
            insurance, please email our new patient liaison:{' '}
            <a href='mailto:customerservice@ktdoctor.com'>
              customerservice@ktdoctor.com
            </a>
            .
            <br />
            <br />
            <div className={` ${styles.small_text}`}>
              If you belong to another HMO/IPA or have another office listed;
              you can still come into our office and have it all changed to one
              of our office and physicians.
              <br />
              <br />
              Or contact us on <a href='tel:8183615437'>(818) 361-5437</a> and
              have it changed over the phone.
            </div>
          </p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg={8} className={` ${styles.request_appointment} px-5`}>
          <h3 className='mb-5'>Request an Appointment</h3>
          <p>
            Our pediatricians are dedicated to providing your family with the
            highest degree of healthcare. We offer you four quick and easy ways
            to get an appointment to care for you and your loved ones.
            <br />
            <br />
            <span>
              Our team members have the expertise and skills to provide the best
              care possible for your child. However, if you're experiencing a
              medical emergency, we recommend calling 911 for emergency aid.
            </span>
          </p>
        </Col>
        <Col lg={4}>
          <StaticImage src='../images/stayhome.png' alt='Stay Home' />
        </Col>
      </Row>

      <hr />
      <h2
        className={`my-5 py-5 text-center  px-5 ${styles.appointment_counter}`}
      >
        Over <span>10,000</span> Appointments Every Month
      </h2>
    </Container>

    <div className={`py-5 ${styles.contact_doctors}`}>
      <Container className='py-5'>
        <h1 className=' text-center py-5'>
          Fastest ways To Get A Response From Your Doctor
        </h1>
        <Row className='gx-5'>
          <Col lg={4}>
            <h3>
              <span>01</span> Patient Portal
            </h3>
            <p>
              <a href='https://patientportal.intelichart.com/login/Account/Login?ReturnUrl=%2f'>
                Registering to your patient portal
              </a>
              {''} is the fastest way to contact your doctor, store your child's
              medical records, refill medication, and make appointments.
            </p>
          </Col>
          <Col lg={4}>
            <h3>
              <span>02</span> Text Us (24/7)
            </h3>
            <p>
              Send us a text on
              <a href='sms:8186582701'>818-658-2701</a>
              <span> English</span>,<br />
              <a href='sms:8184235637'>818-423-5637</a>
              <span> Spanish </span>
              to arrange an <br />
              appointment instantly, 24/7.
            </p>
          </Col>
          <Col lg={4}>
            <h3>
              <span>03</span> Call Us
            </h3>
            <p>
              Reach us on
              <a href='tel:6267958811'> 626-795-8811 </a>
              You can use this hotline to easily make an appointment. However,
              it may take us a few minutes due to the amount of inquiries.
            </p>
          </Col>
        </Row>
      </Container>
    </div>

    <Container className={` ${styles.form} my-5 p-5 `} id='form'>
      <h1 className='mb-4'>Request an appointment</h1>
      <p>
        Complete the form below to request an appointment at After Hours
        Pediatric Urgent Care.
      </p>
      <p>
        For non life threatening emergencies contact us on{' '}
        <a href='tel:8183615437'>(818) 361-5437</a>. If you are having a medical
        emergency, call 911 for emergency medical help. The team members who
        will care for your child have the expertise and skills to provide the
        best care possible.
      </p>

      <Patientform />
    </Container>
  </Layout>
)

export default RequestAppointment
