// extracted by mini-css-extract-plugin
export var appointment_counter = "request-appointment-module--appointment_counter--9InhA";
export var boxOnlinBtn = "request-appointment-module--boxOnlinBtn--IUUdO";
export var boxOnlinBtnTeleMedi = "request-appointment-module--boxOnlinBtnTeleMedi--XCxz7";
export var boxOnline = "request-appointment-module--boxOnline--qEXFC";
export var boxOperator = "request-appointment-module--boxOperator--4ptEq";
export var boxOperatorBtn = "request-appointment-module--boxOperatorBtn--0xV2p";
export var boxTitle = "request-appointment-module--boxTitle--r8jdG";
export var boxnumber = "request-appointment-module--boxnumber--NUQ77";
export var button = "request-appointment-module--button--C4yAH";
export var contact_doctors = "request-appointment-module--contact_doctors--mAEk2";
export var form = "request-appointment-module--form--EVLeI";
export var messenger_button = "request-appointment-module--messenger_button--ZZIA5";
export var request_appointment = "request-appointment-module--request_appointment--KLgft";
export var single_button = "request-appointment-module--single_button--EtkUK";
export var urgentcareneeds = "request-appointment-module--urgentcareneeds--5-nKc";