import React, { useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { navigate } from 'gatsby-link'

import * as styles from '../../styles/components/Appointment/Patientform.module.scss'

// Form Helpers
function encode (data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Patientform = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLocation {
        edges {
          node {
            location
          }
        }
      }
    }
  `)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [dob, setDob] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [appointmentDate, setAppointmentDate] = useState('')
  const [appointmentTime, setAppointmentTime] = useState('')
  const [reason, setReason] = useState('Reason for visit')
  const [preferredMethod, setPreferredMethod] = useState('Preferred Method')
  const [comments, setComments] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'Appointment',
        firstName,
        lastName,
        dob,
        phoneNumber,
        email,
        appointmentDate,
        appointmentTime,
        reason,
        preferredMethod,
        comments
      })
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => console.log(error))
  }

  return (
    <Form
      className={`py-4 ${styles.form}`}
      name='Appointment'
      method='post'
      data-netlify='true'
      action='/success'
      data-netlify-honeypot='bot-field'
      onSubmit={handleSubmit}
    >
      <h4>Patient Details:</h4>
      <Row>
        <Col lg={4} className='py-3'>
          <Form.Control
            type='text'
            name='firstName'
            placeholder="Patient's First Name"
            size='lg'
            required
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </Col>
        <Col lg={4} className='py-3'>
          <Form.Control
            type='text'
            name='lastName'
            placeholder="Patient's Last Name"
            size='lg'
            required
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </Col>
        <Col lg={4} className='py-3'>
          <Form.Control
            type='text'
            placeholder="Patient's Date of Birth"
            name='dob'
            size='lg'
            onFocus={e => (e.target.type = 'date')}
            required
            value={dob}
            onChange={e => setDob(e.target.value)}
          />
        </Col>

        <Col lg={6} className='py-3'>
          <Form.Control
            type='tel'
            name='phoneNumber'
            size='lg'
            placeholder='Cell Phone Number'
            required
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
          />
        </Col>
        <Col lg={6} className='py-3'>
          <Form.Control
            type='email'
            name='email'
            size='lg'
            placeholder='E-mail Address'
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Col>
      </Row>
      <h4>Appointment Details:</h4>
      <Row>
        <Col lg={6} className='py-3'>
          <Form.Control
            type='text'
            name='appointmentDate'
            size='lg'
            placeholder='Preferred Date'
            onFocus={e => (e.target.type = 'date')}
            required
            value={appointmentDate}
            onChange={e => setAppointmentDate(e.target.value)}
          />
        </Col>
        <Col lg={6} className='py-3'>
          <Form.Control
            type='text'
            name='appointmentTime'
            size='lg'
            onFocus={e => (e.target.type = 'time')}
            placeholder='Preferred Time'
            required
            value={appointmentTime}
            onChange={e => setAppointmentTime(e.target.value)}
          />
        </Col>
        <Col className='py-3'>
          <Form.Select
            aria-label='Default select example'
            name='reason'
            value={reason}
            onChange={e => setReason(e.target.value)}
          >
            <option disabled selected value='Reason for visit'>
              Reason for visit
            </option>
            <option value='Sickness'>Sickness</option>
            <option value='Injury'>Injury</option>
            <option value='Physical'>Physical</option>
            <option value='Other'>Other</option>
          </Form.Select>
        </Col>
        <Col className='py-3'>
          <Form.Select
            aria-label='Default select example'
            name='preferredMethod'
            value={preferredMethod}
            onChange={e => setPreferredMethod(e.target.value)}
          >
            <option disabled selected value='Preferred Method'>
              Preferred Method
            </option>
            <option disabled>---Method---</option>
            <option>Telehealth</option>
            <option disabled>---Visit Us---</option>
            {data.allContentfulLocation.edges
              .sort((a, b) => (a.location > b.location ? 1 : -1))
              .map(({ node }) => (
                <option value={node.location} key={node.location}>
                  {node.location}
                </option>
              ))}
          </Form.Select>
        </Col>
      </Row>
      <h4>Additional Comments:</h4>
      <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
        <Form.Control
          as='textarea'
          rows={3}
          name='comments'
          value={comments}
          onChange={e => setComments(e.target.value)}
        />
      </Form.Group>
      <Button type='submit' className='w-md-100 mt-3'>
        SUBMIT
      </Button>
    </Form>
  )
}

export default Patientform
